<template>
  <component
    :is="isExternal ? 'a' : 'router-link'"
    :href="isExternal ? to : undefined"
    :to="isExternal ? undefined : to"
    :target="isExternal ? '_blank' : undefined"
    :rel="isExternal ? 'noopener noreferrer' : undefined"
    class="app-link"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </component>
</template>


<script>
export default {
  name: "AppLink",

  props: {
    to: {
      type: [String, Object],
      required: true,
    },
  },

  computed: {
    isExternal() {
      if (typeof this.to === "string") {
        return this.to.startsWith("http");
      }
    
      return false;
    },
  },
};
</script>


<style>
.app-link {
  /* Your styles for the link */
}
</style>